$primary-color: #73002E;
$secondary-color: #910039;
$default-color: #383838;
$white: #ffffff;

.microsite-menu {
    background-color: $primary-color;
    padding: 12px 60px;
    position: relative;
    @media only screen and (max-width: 768px) {
        padding: 12px 15px;
      }
    .microsite-title {
        a {
            color: $white;
            font-size: 14px;
            font-family: "Open Sans", sans-serif;
            border-right: solid 1px $white;
            padding: 0 30px 0 0;
            margin: 0 30px 0 0;
            float: left;
            text-decoration: none;
            &:hover, &:focus {
                text-decoration: underline;
                text-decoration-thickness: 2px;
            }
            @media only screen and (max-width: 1240px) {
                border-right: none;
                margin: 0;
            }
            span {
                display: block;
                font-size: 12px;
                font-style: italic;
                line-height: 1;
            }
        }
    }
    ul.microsite-menu-items {
        list-style-type: none;
        margin: 0;
        padding: 0;
        gap: 15px;
        float: left;
        @media only screen and (max-width: 1240px) {
            display: none;
        }
            li {
                display: inline-block;
                margin-right: 30px;
                a {
                    color: $white;
                    text-decoration: none;
                    font-family: "Open Sans", sans-serif;
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 2;
                    &:hover {
                        text-decoration: underline;
                        text-decoration-thickness: 2px;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .microsite-menu-hamburger {
            float: right;
            transform: scale(0.5, 0.5);
            margin: -13px -10px -13px 10px;
            display: none;
            .hamburger {
                .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
                    background-color: $white;
                }
            }
            @media only screen and (max-width: 1240px) {
                display: inherit;
            }
        }

        ul.microsite-menu-items-mobile {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: none;
            border-top: 1px solid $white;
            padding: 15px 0 0;
            margin-top: 9px;
                li {
                    margin: 0 0 15px;
                    a {
                        color: $white;
                        text-decoration: none;
                        font-family: "Open Sans", sans-serif;
                        text-transform: uppercase;
                        font-size: 16px;
                        line-height: 2;
                        &:hover {
                            text-decoration: underline;
                            text-decoration-thickness: 2px;
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
}